<template>
  <span v-if="purchasePriceAvg && sellingPrice && getWeq(purchasePriceAvg,
    sellingPrice) > 0">{{ getWeq(purchasePriceAvg,
    sellingPrice) }} %
  </span>
  <span v-else>
    -
  </span>
</template>

<script>

import { getWeq } from '@/utils/functions'

export default {
  name: 'Weq',
  props: {
    purchasePriceAvg: [Number, String],
    sellingPrice: [Number, String], // gets String from AddWineStep Component, possible Refactoring
},
data() {
  return {
  }
},
methods: {
  getWeq
},
}

</script>