<template>
    <div class="viewContainer">
        <b-row>
            <b-col>
                <h1>{{$t('insights.title')}}</h1>
            </b-col>
        </b-row>
        <div class="widgetContainer">
            <b-row>
                <b-col>
                    <div class="widgetBox widgetPadding">
                        <RevenueOptimizeWidget />
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="widgetBox widgetPadding">
                        <EmptySoonWidget />
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>  
</template>
<script>
import EmptySoonWidget from "@/components/EmptySoonWidget.vue";
import RevenueOptimizeWidget from "@/components/RevenueOptimizeWidget.vue";
export default {
    name: 'Insights',
    components: {
        EmptySoonWidget, RevenueOptimizeWidget
    }
}
</script>