<template>
    <div class="revenueOptimizeWidgetContainer" >
        <b-row class="filterRow" align-h="between">
            <b-col>
                <h2>{{$t('insights.revenueOptimizeWidget.title')}}</h2>
            </b-col>
            <b-col>
                <p style="text-align: right" v-if="tableItems.length > 0">
                    <i18n path="insights.revenueOptimizeWidget.description" >
                        <template v-slot:limit>
                            {{widgetData.revenueOptimizePercentLimit}}
                        </template>
                    </i18n>
                </p>
            </b-col>
        </b-row>
        <div class="spacer m"></div>
        <b-row >  
            <b-col>
            <div class="chartOuterContainer">
                <div class="noResults" v-if="tableItems.length < 1 && loading === false">
                    <svg width="117" height="131" viewBox="0 0 117 131" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#000" fill-rule="nonzero">
                            <path d="M65.812 0c1.024 0 1.974 0 2.998.073 24.131 1.383 44.606 20.232 47.75 44.03 2.999 23.144-9.652 43.74-28.81 52.837V131H36.561v-21.833h-7.313c-8.044 0-14.625-6.55-14.625-14.556V80.056H7.311c-5.703 0-9.213-6.26-6.215-11.063l14.113-25.326C18.792 18.995 39.999 0 65.812 0zm5.85 21.833h-11.7c-.732 0-1.317.51-1.39 1.238l-1.097 7.714c-1.755.728-3.436 1.674-4.899 2.838l-7.312-2.91c-.659-.22-1.463 0-1.755.654l-5.85 10.044c-.366.655-.22 1.455.365 1.892l6.216 4.803c-.22.873-.366 1.892-.366 2.838 0 .947.073 1.893.22 2.839l-6.143 4.803c-.585.437-.731 1.237-.366 1.82l5.85 10.116c.366.655 1.097.873 1.829.655l7.239-2.911c1.536 1.164 3.144 2.11 4.972 2.838l1.024 7.714c.146.728.731 1.238 1.463 1.238h11.7c.731 0 1.316-.51 1.462-1.238l1.097-7.714c1.755-.728 3.437-1.674 4.9-2.838l7.239 2.91c.658.292 1.463 0 1.755-.654l5.85-10.116c.366-.655.22-1.383-.366-1.82l-6.069-4.803c.146-.946.22-1.892.22-2.839 0-1.018-.074-1.965-.22-2.838l6.216-4.803c.585-.437.73-1.237.365-1.892l-5.85-10.044c-.365-.655-1.17-.873-1.828-.655l-7.312 2.911c-1.536-1.164-3.145-2.11-4.9-2.838l-1.097-7.714c-.146-.728-.731-1.238-1.462-1.238zm-5.85 18.704c5.777 0 10.457 4.658 10.457 10.407 0 5.75-4.68 10.408-10.457 10.408-5.777 0-10.457-4.658-10.457-10.408 0-5.749 4.68-10.407 10.457-10.407z" fill="#000" fill-rule="nonzero"/>
                        </g>
                    </svg>
                    <p><span class="emptyState">{{$t('insights.revenueOptimizeWidget.noOptimization')}}</span></p>
                </div>
            <div v-if="tableItems.length === 0 && loading === true">
                    <LoadingAnimation />
                </div>
                <div v-if="tableItems.length > 0">
                    <b-table 
                        ref="revenueOptimizeWineList"
                        :items="tableItems" 
                        :fields="fields"
                    >
                        <template #table-colgroup="scope">
                            <col
                            v-for="field in scope.fields"
                            :key="field.key"
                            >
                        </template>
                        <template #cell(venueWine)="data">
                            <b-row>
                                <b-col>
                                    <span>{{data.value.wine.winery.title}}</span>
                                </b-col>
                                <b-col style="text-align: end">
                                    {{data.value.bottleSize.amount}}
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    {{data.value.title}}
                                </b-col>
                                <b-col style="text-align: end">
                                    <span>{{data.value.year}}</span>
                                </b-col>
                            </b-row>
                        </template>
                        <template #cell(chartData)="data">
                            <b-row align-v="center">
                                <b-col  style="text-align:end; padding-right: 20px;">{{data.value.purchasePriceAvg}}</b-col>
                                <b-col  style="text-align:start; padding-left: 20px;">{{data.value.sellingPrice}}</b-col>
                                <b-col><OptimizeHorBarChart :chartData="data.value" :currency="getCurrency" /></b-col>
                            </b-row>
                            
                        </template>
                        <template #cell(weq)="data">
                            <Weq :purchasePriceAvg="data.item.venueWine.purchasePriceAvg" :sellingPrice="data.item.venueWine.sellingPrice"/>
                        </template>
                    </b-table>
                </div>
            </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    import DataService from '@/services/StrapiService'
    import LoadingAnimation from '@/components/LoadingAnimation.vue'
    import OptimizeHorBarChart from '@/components/charts/OptimizeHorBarChart.vue'
    import { getVenueCurrency } from '@/utils/functions'
    import Weq from '@/components/wineDetail/Weq.vue'
    
    export default {
        name: 'RevenueOptimizeWidget',
        components: {
            LoadingAnimation,
            OptimizeHorBarChart,
            Weq
        },
        data() {
            return {
                widgetData: {},
                tableItems: [],
                loading: true,
                notEnoughData: false,
            }
        },
        created () {
            this.loadData()
        },
        methods: {
            loadData() {
                this.loading = true
                DataService.getRevenueOptimizeWidget().then((response) => {
                    this.widgetData = response['data'].data
                    this.refreshWidget()
                    this.loading = false
                }).catch((err) => {
                    // handle error
                    this.loading = false
                    console.log(err)
                })
            },
            refreshWidget() {
                if (this.widgetData.entries.length > 0) {
                    this.notEnoughData = false
                    this.tableItems = this.widgetData.entries
                } else {
                    this.notEnoughData = true
                }
                
            }
        },
        computed: {
            getCurrency() {
                return getVenueCurrency(this.getVenue)
            },
            fields() { 
              return [
                    {
                        key: 'venueWine',
                        label: this.$t('general.wine'),
                        sortable: false
                    },
                    {
                        key: 'chartData',
                        label: this.$t('general.ek_short') + ' ' + this.getCurrency + ' | ' + this.$t('general.vk_short') + ' ' + this.getCurrency,
                        sortable: false
                    },
                    {
                        key: 'weq',
                        label: this.$t('insights.weq'),
                        sortable: false
                    },
                    {
                        key: 'absProfitPercentDiffFromLimit',
                        label: this.$t('insights.revenueOptimizeWidget.underLimitRevenue', { unit : '%', limit : this.widgetData.revenueOptimizePercentLimit}),
                        sortable: true
                    },
                    {
                        key: 'absProfitDiffFromLimit',
                        label: this.$t('insights.revenueOptimizeWidget.underLimitRevenue', { unit : this.getCurrency, limit : this.widgetData.revenueOptimizePercentLimit}),
                        sortable: true
                    },
                ]
            },
            ...mapGetters(['getUser', 'getVenue'])
        },
    }
</script>
<style scoped>
</style>