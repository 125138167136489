<template>
  <div>
    <canvas ref="canvasRef" />
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
import { ref, onMounted, defineComponent } from "@vue/composition-api";

Chart.register(...registerables);
export default defineComponent({
  name: "OptimizeHorBarChart",
  components: {
  },
  props: {
    chartData: {},
    currency: String,
  },
  data() {
    return {

    }
  },
  created () {
  },
  setup(props) {
    const canvasRef = ref(null);
    
    function getDatasets() {
        //negate purchasePrice to draw bar in left direction
        let purchasePriceDataSet = createDataset((props.chartData.purchasePriceAvg * -1), 0, "EK "+props.chartData.currency, "#ffaa00", true, false)
        let zeroDataSet = createDataset(0, 1, "", "#fff", false, false)
        let sellingPriceDataSet = createDataset(0, props.chartData.sellingPrice, "VK "+props.currency, "#7edd37", false, true) 

        return [purchasePriceDataSet, zeroDataSet, sellingPriceDataSet]
    }

    function createDataset(min, max, label, color, roundLeft, roundright) {
      
      let borderRadius = null
      if (roundLeft === true) {
        borderRadius = {
          topLeft: 9999,
          bottomLeft: 9999,
        }
      } 
      if (roundright === true) {
        borderRadius = {
          topRight: 9999,
          bottomRight: 9999,
        }
      }

      return {
        label: label, 
        data: [[min,max]],
        backgroundColor: color,
        borderColor: color,
        borderRadius,
        borderSkipped: false,
        borderWidth: 1,
        barThickness: 10,
        barPercentage: 1.0,
        categoryPercentage: 1.0
      };
    }

    function drawChart() {
      const ctx = canvasRef.value.getContext("2d");
      canvasRef.value.height = 10;
      canvasRef.value.width = 300;

      const oldChart = Chart.getChart(ctx);
      if (oldChart) {
        oldChart.destroy()
      }

      let scaleMax = Math.max(props.chartData.minPurchasePrice, props.chartData.maxSellingPrice)

      new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["EK"],
          datasets: getDatasets(),
        },
        options: {
          events: [], //prevent hover, etc. interactions with chart
          barThickness: 10, 
          indexAxis: "y",
          maintainAspectRatio: false,
          responsive: false,
          scales: {
            x: {
              display: false,
              min: scaleMax * -1,
              max: scaleMax,
              stacked: false, 
            },
            y: {
              display: false,
              stacked: true,
              grid: {
                display: false,
                drawBorder: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    }

    onMounted(() => {
      drawChart()
    })

    return { canvasRef, drawChart };
  },//EO Setup
  methods: {
  },
  watch: {
    chartData() {
        this.drawChart()
    }
  }
});
</script>